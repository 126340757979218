@import "../../../common/styles/variables.scss";

.kb-header {
    @include max-width-centered($max-width);
    background-color: $midnight-color;
    position: sticky; // mandatory for AppLayout
    display: flex;
    height: 80px;
    align-items: center;

    &::before {
        @include edges(0, 0, 0, 0);
        background: inherit;
        position: fixed;
        z-index: -1;
        content: "";
    }

    #kbLogo {
        margin-left: 53px;
        margin-right: 45px;
        cursor: pointer;
    }

    .kbSearchBar input {
        background: white;
        background-image: url(../../../../public/img/search.svg);
        background-size: 16px;
        background-position: 18px;
        background-repeat: no-repeat;

        height: 40px;
        width: 312px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 40%);
        border-radius: 4px;
        border: none;

        cursor: pointer;
        font-size: 14px;
        font-family:$amazon-regular-font;
        letter-spacing: 0;
        color: $black-russian-color;
        text-indent: 46px;
    }
}
