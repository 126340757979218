// colors
$midnight-color: #232F3E;
$black-russian-color: #16191F;
$amazon-regular-font: AmazonEmber-Regular;
$max-width: 1024px;

@mixin max-width-centered($width) {
    max-width: $width;
    margin: 0 auto;
}

@mixin edges($top, $right, $bottom, $left) {
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}